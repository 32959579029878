<template>
  <div class="call-message">
    <b-button
      variant="primary"
      :type="$const.PRIMARY_BUTTON"
      @click="getRecord"
    >
      Посмотреть звонок
    </b-button>
    <BaseVideoViewer
      :show="showRecord"
      :video-src="videoSrc"
      @hide-preview="hideRecord"
    />
  </div>
</template>

<script>
import BaseVideoViewer from '@/components/base/BaseVideoViewer.vue';

export default {
  name: 'CallMessage',
  components: {
    BaseVideoViewer,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showRecord: false,
      record: null,
      payload: null,
    };
  },
  computed: {
    videoSrc() {
      if (this.record) return URL.createObjectURL(this.record);
      return '';
    },
  },
  mounted() {
    this.payload = JSON.parse(this.message.payload);
  },
  methods: {
    async getRecord() {
      this.record = await this.$store.dispatch(this.$types.DOWNLOAD_RECORD, this.payload.callId);
      if (this.record) this.showRecord = true;
    },
    hideRecord(value) {
      this.showRecord = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.call-message {}
</style>
