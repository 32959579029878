<template>
  <div
    v-if="show"
    class="wrapper"
    @click="hidePreview"
  >
    <div
      class="preview"
    >
      <span
        class="close-button"
        @click="hidePreview"
      >
        ×
      </span>
      <div
        class="file-container"
      >
        <video
          :src="videoSrc"
          controls
          class="video-preview"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseVideoViewer',
  props: {
    videoSrc: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hidePreview(event) {
      if (event.target.classList.contains('close-button')) {
        this.$emit('hide-preview', false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  z-index: 1000;
}
.preview {
  margin: auto;
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-button {
  position: absolute;
  top: -35px;
  right: -30px;
  font-size: 40px;
  color: #fff;
  cursor: pointer;
  z-index: 10;
}
.file-container {
  max-height: 57vh;
  max-width: 500px;
  overflow: auto;
}
.video-preview {
  max-height: 57vh;
  max-width: 500px;
}
</style>
