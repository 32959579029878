<template>
  <div class="text-message">
    <p class="text">
      {{ message.text }}
    </p>

    <span class="text-message-date ">
      {{ createdAtFormatted }}
    </span>
  </div>
</template>

<script>
import { format, parseISO } from '@evd3v/date-fns';

export default {
  name: 'TextMessage',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    createdAtFormatted() {
      return format(parseISO(this.message.createdAt), 'HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
.text-message {
  overflow-wrap: break-word;
  max-width: 100%;
  position: relative;
  margin: 4px 0;
  padding: 10px 50px 10px 10px;
  background: #3ca1ff86;
  border-radius: 8px;

  &.text {
    padding-right: 30px;
    white-space: break-spaces
  }
  &.left {
    background: #e7e9e9;
  }

  &-message {
    font-size: 16px;
  }
  &-date {
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #96A9B3;
  }
}
</style>
