<template>
  <div
    v-if="originName"
    class="file-container"
  >
    <div
      v-if="file.mimeType.includes('image')"
      class="file-message"
      :title="originName"
    >
      <div class="file-message-preview-image">
        <template>
          <img
            alt="Изображение"
            :src="file.url"
          >
        </template>
      </div>
      <div class="file-message-date">
        {{ createdAtFormatted }}
      </div>
    </div>

    <div
      v-else
      class="file-message"
      :title="originName"
    >
      <div class="file-message-preview">
        <template v-if="file.mimeType.includes('image')">
          <img
            alt="Изображение"
            :src="file.url"
          >
        </template>
        <template v-else>
          <component
            :is="fileIcon"
            width="32"
            height="32"
          />
        </template>
      </div>
      <div class="file-message-content">
        <div class="file-message-content__filename">
          {{ originName }}
        </div>
        <div class="file-message-content-info">
          <span class="file-message-content-info__ext">{{ ext }}</span>
          <span class="file-message-content-info__size">{{ totalSize }}</span>
        </div>
      </div>
      <div class="file-message-date">
        {{ createdAtFormatted }}
      </div>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from '@evd3v/date-fns';

import MP4Icon from '@/assets/file/mp4.svg';
import MP3Icon from '@/assets/file/mp3.svg';
import PDFIcon from '@/assets/file/pdf.svg';
import DefaultIcon from '@/assets/file/file.svg';

export default {
  name: 'FileMessage',
  components: {
    MP3Icon, MP4Icon, PDFIcon, DefaultIcon,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    originName() {
      return this.file?.originName;
    },
    totalSize() {
      const b = this.file.totalSize;
      const kb = b / 1000;
      const mb = kb / 1000;
      if (b < 1000) return ` ${b} B`;
      if (kb < 1000) return ` ${kb.toFixed(2)} KB`;
      return ` ${mb.toFixed(2)} MB`;
    },
    ext() {
      return this.file.extension.slice(1).toUpperCase();
    },
    createdAtFormatted() {
      return format(parseISO(this.message.createdAt), 'HH:mm');
    },
    fileIcon() {
      let fileComponent = '';

      if (this.ext === 'MP3' || this.ext === 'MP4' || this.ext === 'PDF') {
        fileComponent += this.ext;
      }
      if (!fileComponent) fileComponent += 'Default';

      return `${fileComponent}-icon`;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-container{
  background: #3ca1ff86;
  border-radius: 8px;
  &.left {
    background: #e7e9e9;
  }
}
.file-message {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-family: Muller, serif;
  margin: 5px 0;
  padding: 10px 50px 10px 10px;

  &-preview-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 42px);
    height: 100%;
    &__filename {
      font-size: 14px;
      color: #3e3e3e;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-info {
      margin-top: 5px;
      font-size: 10px;
      color: #3d3d3d;
    }
  }
  &-date {
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #96A9B3
  }
}
</style>
