<template>
  <div
    v-if="file || message?.messageType"
    class="message-container"
    :class="positionClasses"
    :data-position="messagePosition"
  >
    <div class="text-message">
      <b-form-checkbox
        v-if="message?.messageType !== 'Call'"
        v-model="message.checked"
        class="checkbox-message"
        :class="
          messagePosition === 'left' ?
            'checkbox-message-left'
            : 'checkbox-message-right'"
        @input="checkMessage(message)"
      />
      <component
        :is="messageComponent"
        :message="message"
        :file="file"
        :class="messagePosition"
      />
    </div>
  </div>
</template>

<script>
import TextMessage from '@/components/Consultations/messages/TextMessage';
import FileMessage from '@/components/Consultations/messages/FileMessage';
import CallMessage from '@/components/Consultations/messages/CallMessage';

const MESSAGE_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
};

export default {
  name: 'MessageContainer',
  components: {
    TextMessage,
    FileMessage,
    CallMessage,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
  },
  computed: {
    positionClasses() {
      const classes = [];

      if (this.message?.messageType === 'Call') {
        classes.push('w-full text-center message-container--media-file');
      }

      return classes;
    },
    messageComponent() {
      return `${this.message.messageType}-message`;
    },
    messagePosition() {
      if (this.message?.member?.memberType.includes('Patient')) {
        return MESSAGE_POSITION.LEFT;
      }
      return MESSAGE_POSITION.RIGHT;
    },
    payload() {
      return JSON.parse(this.message?.payload);
    },
    file() {
      const fileNames = this.payload?.fileNames;
      if (fileNames) {
        return this.files.find((file) => file.name === fileNames[0]);
      }
      return null;
    },
  },
  methods: {
    checkMessage(message) {
      this.$emit('input', message);
    },
  },
};
</script>

<style lang="scss" scoped>
.message-container {
  max-width: 80%;

  &--media-file {
    max-width: 100%;
  }

  &[data-position='left'] {
    align-items: flex-start;
    margin-right: auto;
  }
  &[data-position='right'] {
    align-items: flex-end;
    margin-left: auto;
  }
}
.text-message {
    word-wrap: break-word;
    max-width: 100%;
    min-width: 50px;
    position: relative;
    margin-top: 5px;
    padding: 0px 10px 10px 10px;
  }
.checkbox-message-left{
    position: absolute;
    top:  calc(50% - 15px);
    left: -25px
  }
  .checkbox-message-right{
    position: absolute;
    top: calc(50% - 15px);
    right: -30px
  }
</style>
